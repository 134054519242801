
import React, { useContext, Children } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import RedAlert from '../ui-elements/RedAlert'



type Props = {
  questionId?: string
  checklistId?: string
  handleChangeSelectMultiple: AnyFunction
  defaultValue: null | string | string[]
  questionOptions: null | ObjectStringKeyAnyValue
}

function QuestionSelectMultiple({
  questionId,
  checklistId,
  handleChangeSelectMultiple,
  defaultValue,
  questionOptions
}: Props) {

  const {
    tableData
  } = useContext(GlobalContext)


  return <div className={`w-full flex flex-row gap-2 items-center justify-center`}>

  {(!questionOptions || questionOptions.length < 1) && <RedAlert>No options for this question</RedAlert>}

  {questionOptions && questionOptions.length > 0 && <div className={`flex flex-col gap-2`}>
    {questionOptions.map((option: string, index: number) => {
      return <div key={index}>
        <Button
          onClick={(e: any) => handleChangeSelectMultiple(option)}
          text={option}
          variant={defaultValue instanceof Array && defaultValue.includes(option) ? 'primary' : 'white'}
          size={'big'}
          wordWrap={true}
        />
      </div>
    })}
  </div>}

</div>
}
export default QuestionSelectMultiple