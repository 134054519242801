import { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { useVideo } from '../context/video/VideoContext';
import PhotoNavigation from './PhotoNavigation';
import PhotoPreview from './PhotoPreview';
import YellowAlert from '../ui-elements/YellowAlert';



type Props = {
  questionId?: string
  checklistId?: string
  answerPhoto: any
  setAnswerPhoto: any
  defaultValue: null | string | string[]
  setAnswerNote: React.Dispatch<React.SetStateAction<any>>
  answerNote: string | null
  jpegString: string | null
  setJpegString: React.Dispatch<React.SetStateAction<any>>
}

function QuestionPhoto({
  answerPhoto,
  setAnswerPhoto,
  setAnswerNote,
  answerNote,
  questionId,
  checklistId,
  jpegString, 
  setJpegString
}: Props) {

  const { setQRCodesSearchOptions, aiResults } = useVideo()
  // const [jpegString, setJpegString] = useState<string | undefined>(undefined)
  const {   
    tableData,
    setIsVideoMode,
    setIsVideoPlaying
  } = useContext(GlobalContext)


  useEffect(() => {
    setIsVideoMode(true)
    setIsVideoPlaying(true)
    setQRCodesSearchOptions({'enabled': true})
    }, [])




  return <div className={`max-w-3xl w-full pt-0 pb-8 px-3 flex flex-col gap-2 items-center justify-center`}>

    {jpegString && <PhotoPreview
      jpegString={jpegString}
    />}
    
  </div>
}
export default QuestionPhoto