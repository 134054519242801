import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import Button from '../formfields/Button'
import Text from '../formfields-new/Text'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { useNavigate, useParams } from 'react-router-dom'
import GuestUserLoginForm from './GuestUserLoginForm'

type Props = {
  loginUrl: string
  loginOptions: null | ObjectStringKeyAnyValue
}


function NotLoggedIn({
  loginUrl,
  loginOptions
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
    currentOrganisation,
    orgIdFromQrCode
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)
  const [savingProgress, setSavingProgress] = useState(false)
  const [showGuestLogin, setShowGuestLogin] = useState<boolean | null>(null)
  const [showCognitoLogin, setShowCognitoLogin] = useState<boolean | null>(null)
  const [deleteLocalStorage, setDeleteLocalStorage] = useState(false)


  useEffect(() => {
    if (checklistId && loginOptions && loginOptions['Checklists']) {
      if (loginOptions['Checklists'].includes('guest')) {
        setShowGuestLogin(true)
      } else {
        setShowGuestLogin(false)
      }
      if (loginOptions['Checklists'].includes('cognito')) {
        setShowCognitoLogin(true)
      } else {
        setShowCognitoLogin(false)
      }
    } else if (!checklistId) {
      setShowCognitoLogin(true)
    }
  }, [loginOptions, checklistId])

  useEffect(() => {
    const mismatchedChecklistId = localStorage.getItem('mismatchedChecklistId')
    mismatchedChecklistId && checkForChecklistIdInLocalStorage(mismatchedChecklistId)
  }, [])


  useEffect(() => {
    if (deleteLocalStorage){
      localStorage.removeItem('mismatchedChecklistId')
    }
  }, [deleteLocalStorage])


  const checkForChecklistIdInLocalStorage = (mismatchedChecklistId: string) => {
    console.log('💟')
    const windowpath = window.location.pathname
    if (windowpath === "/" && mismatchedChecklistId) {
      console.log('🚨 Found mismatched checklist id in local storage')
      navigate(`/start-checklist/${mismatchedChecklistId}`)
      setDeleteLocalStorage(true)
    } else {
      console.log('🪼')
    }
  }





  const in_iframe = window.parent.location !== window.location


  return <div className={`bg-brandblue flex flex-col min-h-screen items-center justify-center text-center`}>




    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>Logging you in...</p></Spinner>
    </Modal>}



    <div className={`w-64 flex flex-col gap-6 items-center justify-center text-white`}>
      <img
        src={`/logos/logo-white-no-strapline.svg`}
        alt={'ChecQR logo'}
      />

      {!showForm && <div className={`w-full flex flex-col gap-4`}>
        <p>Welcome to ChecQr!</p>


        {showCognitoLogin === false && showGuestLogin === false && <RedAlert>No login options set up for this organisation</RedAlert>}
        {showCognitoLogin === null && showGuestLogin === null && <Spinner>Checking login options...</Spinner>}
        {showCognitoLogin && <Button
          onClick={(event: Event) => {
            event.preventDefault()
            if (in_iframe) {
              window.parent.location.href = loginUrl
            } else {
              window.location.href = loginUrl
            }
          }}
          text={'Log in with email'}
          variant={'secondary'}
          size={'big'}
          sameWindow={true}
        />}

        {showGuestLogin && <Button
          onClick={() => setShowForm(true)}
          text={'Continue as guest'}
          variant={'secondary'}
          size={'big'}
          sameWindow={true}
        />}
      </div>}


      {showForm && showGuestLogin && <GuestUserLoginForm
        setShowForm={setShowForm}
        setSavingProgress={setSavingProgress}
      />}



    </div>

  </div>
}
export default NotLoggedIn