import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { subscribe } from "../utils/pubsub"
import Spinner from "../ui-elements/Spinner"
import Button from '../formfields/Button'
import Text from '../formfields-new/Text'
import RedAlert from '../ui-elements/RedAlert'
import Modal from '../ui-elements/Modal'
import { useParams } from 'react-router-dom'

type Props = {
    setSavingProgress?: React.Dispatch<React.SetStateAction<boolean>>
    setShowForm: React.Dispatch<React.SetStateAction<boolean>>
}


function GuestUserLoginForm({
    setSavingProgress,
    setShowForm
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket,
    currentOrganisation,
    orgIdFromQrCode
  } = useContext(GlobalContext)

  const { checklistId } = useParams()
  const initialFormData = { "UserName": { "value": '', "required": true, "type": "text" } }
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)




  const validateAndSave = () => {
    if (
      !formFields["UserName"]['value'] ||
      formFields["UserName"]['value'].trim() === ''
    ) {
      setErrorMessage('Please enter a valid username')
    } else {
      setErrorMessage(null)
      setSavingProgress && setSavingProgress(true)
      const payload = JSON.stringify({
        action: 'guestLogin',
        guestLoginAction: 'login',
        localGuestDetails: {
          "UserName": `${formFields['UserName'].value}`,
          "OrganisationId": `${orgIdFromQrCode ? orgIdFromQrCode : currentOrganisation}`
        }
      })
      sendMessageToWebsocket(payload)
    }
  }



  return <div className={`w-full flex flex-col gap-4`}>

  <h3 className={`font-righteous text-2xl`}>Log in as a guest</h3>


  <p>What is your name?</p>
  <Text
    labelText={''}
    formFields={formFields}
    setFormFields={setFormFields}
    fieldName={'UserName'}
    placeholder={''}
    errorMessage={'Please enter your name'}
    size={'big'}
    center={true}
    value={formFields && formFields['UserName'] && formFields['UserName'].value || ''}
  />

  {errorMessage && <RedAlert>{errorMessage}</RedAlert>}

  <div className={`w-full flex flex-row gap-4`}>
    <Button
      onClick={() => setShowForm(false)}
      text={'Cancel'}
      variant={'gray'}
      size={'big'}
      sameWindow={true}
    />
    <Button
      onClick={validateAndSave}
      text={'Continue'}
      variant={'secondary'}
      size={'big'}
      sameWindow={true}
    />
  </div>
</div>
}
export default GuestUserLoginForm