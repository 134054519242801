import React, { useEffect, useState, useContext } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import FooterNavButtons from '../shared-components/FooterNavButtons'
import HeaderDetails from '../shared-components/HeaderDetails'
import Card from '../ui-elements/Card'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import SummaryCheckWindow from './SummaryCheckWindow'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import { subscribe } from '../utils/pubsub'
import Modal from '../ui-elements/Modal'
import YellowAlert from '../ui-elements/YellowAlert'
import GreenAlert from '../ui-elements/GreenAlert'
import ChecklistStatus from './ChecklistStatus'
import QuestionSummaryDetail from './QuestionSummaryDetail'
import { sortAllQuestionAnswersForCheckPeriod } from '../utils/sortAllQuestionAnswersForCheckPeriod'


type Props = {
}

function ChecklistQuestions({
}: Props) {

  const {
    tableData,
    isSignoffRequired,
    checkPeriodIdsForActiveCheckists,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { checkPeriodId } = useParams()
  const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
  const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
  const [showNotFound, setShowNotFound] = useState(false)
  const [checklistProgress, setChecklistProgress] = useState<null | ObjectStringKeyAnyValue>(null)
  const [nextButtonText, setNextButtonText] = useState('')
  const [nextButtonUrl, setNextButtonUrl] = useState<null | string>(null)
  const [sortedQuestionsAndAnswers, setSortedQuestionsAndAnswers] = useState<null | Array<ObjectStringKeyAnyValue>>(null)


  const thisCheckPeriodSignoff = checkPeriodId && tableData && tableData.CheckPeriods && tableData.CheckPeriods[checkPeriodId] && tableData.CheckPeriods[checkPeriodId]['Signoff'] ? tableData.CheckPeriods[checkPeriodId]['Signoff'] : null



  useEffect(() => {
    //console.log(`🚨 running update`)
    if (checkPeriodId && tableData) {
      const progress = getChecklistProgress(checkPeriodId, tableData)
      if (progress) {
        setChecklistProgress(progress)
      }

      if (
        tableData.Checklists
        && tableData.ChecklistPeriods
        && tableData.ChecklistPeriods[checkPeriodId]
        && tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']
        && tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]
      ) {

        const currentChecklist = tableData.Checklists[tableData.ChecklistPeriods[checkPeriodId]['ChecklistId']]
        setThisChecklist(currentChecklist)


        const allQuestionAnswersArray = sortAllQuestionAnswersForCheckPeriod(checkPeriodId, tableData)
        setSortedQuestionsAndAnswers(allQuestionAnswersArray)

        // get questions for this checklist
        // filter follow up questions
        // and sort by order
        if (tableData['ChecklistQuestions']) {
          let questionsArray: any[] = []
          for (const parentQuestionId in tableData['ChecklistQuestions']) {
            const parentQuestion = tableData['ChecklistQuestions'][parentQuestionId]
            if (
              parentQuestion.ChecklistId === currentChecklist.Id &&
              (!parentQuestion['parentQuestionId'] && !parentQuestion['parentActionId'])
            ) {
              questionsArray.push(parentQuestion)

              for (const followUpQuestionId in tableData['ChecklistQuestions']) {
                const followUpQuestion = tableData['ChecklistQuestions'][followUpQuestionId]
                if (
                  followUpQuestion.ChecklistId === currentChecklist.Id &&
                  followUpQuestion['parentQuestionId'] === parentQuestionId
                ) {
                  questionsArray.push(followUpQuestion)
    
                }
              }

            }
          }

          questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
          setThisChecklistQuestions(questionsArray)
        }

      } else {
        setShowNotFound(true)
      }
    }
  }, [checkPeriodId, tableData])




  useEffect(() => {
    setNavButtons()
  }, [checklistProgress, isSignoffRequired, thisCheckPeriodSignoff])


  const setNavButtons = () => {
    // if checklist isn't complete, show scan qr code
    if (
      checklistProgress &&
      checklistProgress.numberOfExpectedAnswers < checklistProgress.numberOfCompletedAnswers) {
      setNextButtonText('Scan QR code')
      setNextButtonUrl(`/checklists/${checkPeriodId}/scan-qr-code`)
    }
    // if checklist is complete and signoff is required but not done yet, 
    // show complete checklist
    else if (
      isSignoffRequired &&
      !thisCheckPeriodSignoff &&
      checklistProgress &&
      (checklistProgress.numberOfExpectedAnswers === checklistProgress.numberOfCompletedAnswers)) {
      setNextButtonText('Sign off')
      setNextButtonUrl(`/checklists/${checkPeriodId}/complete-checklist`)
    }
  }


  // console.log(sortedQuestionsAndAnswers)


  return <div className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}>


    <div className={`w-full flex flex-col items-center`}>
      <HeaderDetails
        titleText={thisChecklist ? thisChecklist['ChecklistName'] : 'unnamed checklist'}
      />
      <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>

        {!tableData && <Spinner><p>Loading...</p></Spinner>}
        {showNotFound && <RedAlert>Checklist period {checkPeriodId} not found</RedAlert>}
        {thisChecklistQuestions && thisChecklistQuestions.length === 0 && <RedAlert icon={true}>No questions have been set up for this checklist</RedAlert>}



        <ChecklistStatus
          checkPeriodId={checkPeriodId}
          checklistProgress={checklistProgress}
          thisCheckPeriodSignoff={thisCheckPeriodSignoff}
        />

        {sortedQuestionsAndAnswers && thisChecklistQuestions && thisChecklistQuestions.length > 0 && <div className={`w-full flex flex-col gap-6`}>
          {thisChecklistQuestions.map((question: ObjectStringKeyAnyValue, index: number) => {
            const answersForThisQuestion = sortedQuestionsAndAnswers.filter((answer: ObjectStringKeyAnyValue) => answer.QuestionId === question.Id)
            const parentQuestion = tableData.ChecklistQuestions[question.parentQuestionId]

            if (answersForThisQuestion.length > 0) {
             
            return <div key={index} className={`w-full flex flex-col gap-2`}>

              <div className={`w-full flex flex-col`}>
              {question.QuestionFormat !== 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}
              {question.QuestionFormat === 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>Temperature check for fridge: {question.FridgeId} </h5>}

              {parentQuestion && <p className={`text-xs italic`}>Followup question for: "{parentQuestion.QuestionText}"</p>}
              </div>

              {answersForThisQuestion.map((answer: ObjectStringKeyAnyValue, index: number) => {
                const question = tableData.ChecklistQuestions[answer.QuestionId]
                return <QuestionSummaryDetail
                  key={index}
                  questionAnswer={answer}
                  checkPeriodId={checkPeriodId}
                  question={question}
                  answerNumber={index + 1}
                  answersForThisQuestion={answersForThisQuestion}
                  sortedQuestionsAndAnswers={sortedQuestionsAndAnswers}
                />
              })}
            </div>}
          })}
        </div>}

      </div>
    </div>

    <FooterNavButtons
      backButtonUrl={`/`}
      backButtonText={'Back'}
      nextButtonUrl={nextButtonUrl}
      nextButtonText={nextButtonText}
    />

  </div>
}

export default ChecklistQuestions