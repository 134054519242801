import React, { createContext, useContext } from 'react';

interface VideoContextType {
    streamToMyCanvas: (canvasRef: React.RefObject<HTMLCanvasElement>) => void
    stopStreamingToMyCanvas: (canvasRef: React.RefObject<HTMLCanvasElement>) => void
    availableCameras: MediaDeviceInfo[]
    selectCamera: (cameraId: string) => void
    selectedCamera: string|null
    cameraState: string
    QRCodesPresent: any
    setQRCodesPresent: React.Dispatch<any>
    setQRCodesSearchOptions: (options: any) => void
    captureAsJPEGString: (width?: number | undefined) => string|undefined
    straightenedImageUrl: string
    straightenedImageNoQRUrl: string
    aiRecognizerState: string
    aiResults: any
    clearAiResults: ()=>void
    clearCameraStateVariables: ()=>void
}

const VideoContext = createContext<VideoContextType | null>(null);



export const useVideo = () => {
    const context = useContext(VideoContext);
    if (context === null) throw new Error("useVideo must be used within a VideoProvider");
    return context;
}

export default VideoContext;