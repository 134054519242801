export const findNextQuestion = (currentQuestionAnswerId, allQuestionAnswersArray) => {
  //console.log('🔎 finding next question...')


  // filter out any answers that are not pending
  let availableAnswers = []
  allQuestionAnswersArray.forEach((answer) => {
    if (
      answer.Pending === true
    ) {
      availableAnswers.push(answer)
    }
  })

  // if no current answer is provided, return the first in the list
  if (!currentQuestionAnswerId) {
    return availableAnswers[0].Id
  }

  // if an answer is provided, return the next in the list
  if (currentQuestionAnswerId) {
    const currentAnswerIndex = allQuestionAnswersArray.findIndex((answer) => answer.Id === currentQuestionAnswerId)


    const pendingAnswersBeforeThisOne = allQuestionAnswersArray.slice(0, currentAnswerIndex).filter((answer) => answer.Pending === true)
    const pendingAnswersAfterThisOne = allQuestionAnswersArray.slice(currentAnswerIndex + 1).filter((answer) => answer.Pending === true)

    if (pendingAnswersAfterThisOne.length > 0) {
      return pendingAnswersAfterThisOne[0].Id
    } else {
      if (pendingAnswersBeforeThisOne.length > 0) {
        return pendingAnswersBeforeThisOne[0].Id
      } else {
        return 'AllQuestionsComplete'
      }
    }

  }

  return null

}