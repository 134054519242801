import React, { useEffect, useState, useContext } from 'react'
import { useVideo } from '../context/video/VideoContext';
import { GlobalContext } from '../GlobalContext'
import { Link, useNavigate } from "react-router-dom"
import Modal from './Modal'
import Button from '../formfields/Button'
import { subscribe } from "../utils/pubsub"
import Spinner from './Spinner'
import EnabledDisabled from '../formfields/EnabledDisabled';



type Props = {
  loggedIn: Boolean | string
  logoutUrl: string
  setLoggedIn: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
}








function Header({
  loggedIn,
  logoutUrl,
  setLoggedIn,
  setUserData
}: Props) {



  const {
    tableData,
    userData,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    isVideoMode,
    isVideoPlaying,
    isInPhoneMode,
    setIsInPhoneMode,
    setIsVideoPlaying,
    handleLogout
  } = useContext(GlobalContext)





  const navigate = useNavigate()
  const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}
  const [showMenu, setShowMenu] = useState(false)
  const [showOrgsMenu, setShowOrgsMenu] = useState(false)
  const [showCameraOptions, setShowCameraOptions] = useState(false)
  const { availableCameras, selectedCamera, selectCamera, cameraState } = useVideo();

  useEffect(() => {
    if (!showMenu) {
      setShowOrgsMenu(false)
    }
  }, [showMenu])


  const toggleVideo = (fieldName: string, enabled: boolean) => {
    setIsVideoPlaying(enabled)
  }

  return <div className={`${showMenu ? 'min-h-screen' : ''} z-10 w-full fixed flex flex-col`}>

    <header className={`
   ${isVideoMode && showMenu ? ' md:bg-transparent bg-brandblue ' : ''}
   ${!isVideoMode ? ' bg-brandblue ' : ''}
  flex flex-col items-center justify-center
    `}
      style={{
        height: '60px'
      }}
    >
      <div className={`max-w-3xl w-full px-5 text-white flex flex-row justify-between items-center gap-6`}>
        <Link to={'/'}>
          <img
            src={`/logos/logo-white-no-strapline.svg`}
            alt='ChecQR Logo'
            className={`border-0 py-1`}
            style={{ height: '45px' }}
          />
        </Link>








        <div className={`z-10 w-12  rounded-t
        ${!isVideoMode ? ' md:border-x-2 border-brandblue ' : ' '}
        ${!showMenu && isVideoMode ? ' pl-1 pt-1 ' : ' px-2 pt-2 '}
        ${showMenu ? ' bg-tealgreen ' : ' '} 
        `}
          style={{
            margin: '10px 0 0 0',
            height: '50px'
          }}
        >
          {showMenu &&
            <img
              src='/icons/close-x.svg'
              alt='Menu'
              className={`w-full cursor-pointer`}
              onClick={() => setShowMenu(false)} />}

          {!showMenu && isVideoMode && <img
            src='/icons/hamburger-brandblue.svg'
            alt='Menu'
            className={`w-full cursor-pointer rounded bg-white`}
            style={{ padding: '6px' }}
            onClick={() => setShowMenu(true)} />
          }

          {!showMenu && !isVideoMode && <img
            src='/icons/hamburger.svg'
            alt='Menu'
            className={`w-full cursor-pointer`}
            onClick={() => setShowMenu(true)} />
          }
        </div>
      </div>
    </header>
    {showMenu && <div className={`md:h-auto h-full w-full absolute flex flex-col justify-center items-center text-sm text-white`}
      style={{ marginTop: '60px' }}
    >

      <div className={`h-full w-full flex flex-col items-end md:max-w-3xl md:px-5`}>

        <div
          className={`
            ${isVideoMode  ? '' : ' md:border-x-2 md:border-b-2 md:rounded-b border-brandblue '}
            md:max-w-sm w-full h-full bg-gradient-to-b from-tealgreen to-tealblue px-5 py-4 text-white flex flex-col gap-1 p-2`}
          onMouseLeave={() => setShowMenu(false)}
          style={{

          }}
        >

          <div className={`flex flex-col pb-2 gap-1 text-brandblue`}>
            <div className={`flex flex-row gap-2 items-center`}>
              <img src='/icons/usericon-brandblue.svg' className={`w-5`} alt='' />
              {userData && <p className={`font-bold text-xl`}>{userData.name}</p>}
            </div>
            {currentOrganisation && organisations && organisations[currentOrganisation] && <p className={`text-md`}>
              {organisations[currentOrganisation]}
            </p>}
          </div>

          {/*
          <select onChange={(event) => { selectCamera(event.currentTarget.value) }}>{availableCameras?.map((camera) => {
            let dot = ''
            if (selectedCamera == camera.deviceId && cameraState === 'RUNNING') {
              dot = '🔴'
            }

            return <option value={`${camera.deviceId}`} key={`${camera.deviceId}`}>{`${dot}${camera.label}`}</option>
          })}
          </select> */}


          {/* {isVideoMode && <div
            className={`text-brandblue bg-white font-bold text-lg px-5 py-3 rounded  flex flex-row justify-between gap-2 items-center`}>
            Video playing:
            <EnabledDisabled
              fieldName={`EnableOrDisableVideo`}
              handleChange={toggleVideo}
              enabled={isVideoPlaying}
            />
          </div>} */}


          {isVideoMode && !isVideoPlaying && <Button
            text="Start video"
            onClick={() => setIsVideoPlaying(true)}
            size={'big'}
            variant={'white'}
            align={`left`}
          />}

          {isVideoMode && isVideoPlaying && <Button
            text="Stop video"
            onClick={() => setIsVideoPlaying(false)}
            size={'big'}
            variant={'white'}
            align={`left`}
          />}

          {availableCameras && availableCameras.length > 0 && <div>
            <div
              className={`bg-brandblue font-bold text-lg px-5 py-3 rounded hover:opacity-90`}>

              <div className={`flex flex-row items-center gap-2`} onClick={() => setShowCameraOptions(!showCameraOptions)}>
                <p className={``}>Switch camera</p>
                <img src='/icons/arrow.svg' alt='' className={`h-3 ${showCameraOptions && 'rotate-90'}`} />
              </div>

              {showCameraOptions &&
                <div className={`pt-2 text-left text-sm`}>
                  {
                    availableCameras.map((camera, index) => {
                      let isCurrentlySelected = false
                      let isCurrentlyRunning = false

                      if (selectedCamera == camera.deviceId) {
                        isCurrentlySelected = true
                        if (cameraState === 'RUNNING') {
                          isCurrentlyRunning = true
                        }
                      }

                      return <div
                        key={index}
                        className={`${isCurrentlySelected ? '' : 'underline'} py-1 font-normal`}
                        onClick={() => {
                          setShowMenu(false)
                          selectCamera(camera.deviceId)
                        }}
                      >
                        {`${isCurrentlyRunning ? '🔴' : ''} ${camera.label}`}
                      </div>
                    })
                  }
                </div>}
            </div>
          </div>}



          {switchCurrentOrganisation && Object.keys(organisations).length > 1 && <div>
            <div
              className={`bg-brandblue font-bold text-lg px-5 py-3 rounded hover:opacity-90`}>

              <div className={`flex flex-row items-center gap-2`} onClick={() => setShowOrgsMenu(!showOrgsMenu)}>
                <p className={``}>Switch organisation</p>
                <img src='/icons/arrow.svg' alt='' className={`h-3 ${showOrgsMenu && 'rotate-90'}`} />
              </div>

              {organisations && switchCurrentOrganisation && showOrgsMenu &&
                <div className={`pt-2 text-left text-sm`}>
                  {
                    Object.keys(organisations).map((id, index) => {
                      return <div
                        className={`${currentOrganisation === id && 'hidden'} underline py-1 font-normal`}
                        key={index}
                        onClick={() => {
                          setShowMenu(false)
                          switchCurrentOrganisation(id, true)
                          navigate('/')
                        }}>
                        {organisations[id]}
                      </div>
                    })
                  }
                </div>}
            </div>
          </div>}

          <Button
            onClick={() => {
              setShowMenu(false)
              navigate('/checklists')
            }}
            text='All checklists'
            variant={`primary`}
            align={'left'}
            size={'big'}
          />


          <Button
            onClick={() => {
              setShowMenu(false)
              navigate(`/scan-qr-code`)
            }}
            text={'Scan QR code'}
            variant={`primary`}
            align={'left'}
            size={'big'}
          />


          <Button
            onClick={handleLogout}
            text='Log out'
            variant={`primary`}
            align={'left'}
            size={'big'}
          />
        </div>
      </div>
    </div>}
  </div>
}
export default Header