
import React, { useContext, useRef, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import { Link } from 'react-router-dom'
import Button from '../formfields/Button'
import Spinner from '../ui-elements/Spinner'
import { useVideo } from '../context/video/VideoContext';
import RedAlert from '../ui-elements/RedAlert'
import PhotoPreview from './PhotoPreview'
import PhotoNavigation from './PhotoNavigation'
import FridgeReadingDetected from './FridgeReadingDetected'
import { checkIfAssetExists } from '../utils/checkIfAssetExists'
import { getAssetIdFromUrl } from '../utils/getAssetIdFromUrl'
import YellowAlert from '../ui-elements/YellowAlert'
import Modal from '../ui-elements/Modal'
import { concat } from '@tensorflow/tfjs'
import Card from '../ui-elements/Card'



type Props = {
  questionId?: string
  checklistId?: string
  questionAnswer: string | null | string[]
  setQuestionAnswer: React.Dispatch<React.SetStateAction<any>>
  defaultValue: null | string | string[]
  fridgeId: string | null
  answerNote: string | null
  setAnswerNote: React.Dispatch<React.SetStateAction<any>>
  answerPhoto: string | null
  setAnswerPhoto: React.Dispatch<React.SetStateAction<any>>
  isAnswerManuallyEdited: boolean
  setIsAnswerManuallyEdited: React.Dispatch<React.SetStateAction<any>>
  jpegString: string | null
  setJpegString: React.Dispatch<React.SetStateAction<any>>
}

function QuestionFridgeTemperature({
  questionId,
  checklistId,
  questionAnswer,
  setQuestionAnswer,
  defaultValue,
  fridgeId,
  answerNote,
  setAnswerNote,
  answerPhoto,
  setAnswerPhoto,
  isAnswerManuallyEdited,
  setIsAnswerManuallyEdited,
  jpegString,
  setJpegString
}: Props) {

  const {
    tableData,
    isVideoMode,
    setIsVideoMode,
    isVideoPlaying,
    setIsVideoPlaying
  } = useContext(GlobalContext)



  // const [jpegString, setJpegString] = useState<string | undefined>(undefined)
  const [showReadingDetectedModal, setShowReadingDetectedModal] = useState(false)
  const [showInvalidAssetMessage, setShowInvalidAssetMessage] = useState(false)
  const [showNoDigitalDisplayMessage, setShowNoDigitalDisplayMessage] = useState(false)
  const [showUnexpectedQrCodeModal, setShowUnexpectedQrCodeModal] = useState(false)
  const { QRCodesPresent, setQRCodesPresent, setQRCodesSearchOptions, captureAsJPEGString, straightenedImageUrl, straightenedImageNoQRUrl, aiResults, clearCameraStateVariables } = useVideo()
  const defaultQrSearchOptions = {
    'enabled': true,
    'expectedDomains': ['QRWL.ME', 'CHQN.IN'],
    'searchArea': { top: 0, left: 0, width: 1, height: 1 },
    'showSearchArea': false,
    'takeStraightenedPhoto': true,
    'attemptAiReading': true
  }


  useEffect(() => {
    let isFridgeIdValid = false
    let doesAssetMatch = null
    let doesAssetHaveDigitalDisplay = false
    let letHasQrBeenFound = false

    if (fridgeId && tableData) {
      // check if asset exists
      if (tableData && checkIfAssetExists(fridgeId, tableData)) {
        isFridgeIdValid = true
      }
      // check if asset from QR code matches asset associated with this question
      if (QRCodesPresent && QRCodesPresent.length > 0) {
        const assetIdFromDataUrl = getAssetIdFromUrl(QRCodesPresent[0])
        letHasQrBeenFound = true
        doesAssetMatch = assetIdFromDataUrl === fridgeId
      }
      // check if asset has a digital display
      if (tableData.Assets && tableData.Assets[fridgeId] && tableData.Assets[fridgeId].HasDigitalReadout === true) {
        doesAssetHaveDigitalDisplay = true
      }

      if (isFridgeIdValid) {
        if (doesAssetHaveDigitalDisplay) {
          // start the camera if the id is valid and has a digital readout,
          // but no qr code has been detected yet
          startCamera()
          setQRCodesSearchOptions(defaultQrSearchOptions)

          if (letHasQrBeenFound && doesAssetMatch === true) {
            // if a QR is detected and it matches the asset,
            // show the reading detected screen
            capture()
            setShowReadingDetectedModal(true)
          } else if (letHasQrBeenFound && doesAssetMatch === false) {
            setShowUnexpectedQrCodeModal(true)
          }

        }
        else {
          // allow a manual reading to be entered if it has no digital display
          setShowNoDigitalDisplayMessage(true)
          setShowReadingDetectedModal(true)
          stopCamera()
        }
      } else {
        // if asset is invalid, show warning message
        setShowInvalidAssetMessage(true)
        stopCamera()
      }
    }
    if (!QRCodesPresent) {
      setShowReadingDetectedModal(false)
    }

  }, [QRCodesPresent, tableData, fridgeId])


  const capture = () => {
    const jpgData = captureAsJPEGString(600)
    setAnswerPhoto && setAnswerPhoto(jpgData)
    setJpegString && setJpegString(jpgData)
  }

  const retakePhoto = () => {
    //setShowReadingDetectedModal(false)
    setQRCodesPresent(null)
    setIsVideoMode(true)
    setIsVideoPlaying(true)
    setQRCodesSearchOptions(defaultQrSearchOptions)
    clearCameraStateVariables()
  }


  const startCamera = () => {
    setIsVideoMode(true)
    setIsVideoPlaying(true)
  }
  const stopCamera = () => {
    setIsVideoMode(false)
    setIsVideoPlaying(false)
  }



  return <div
    className={`max-w-3xl w-full pt-0 pb-8 px-3 flex flex-col gap-2 justify-center items-center`}
  >

    {/* <div className={`mt-24 bg-white p-2 rounded`}>
      {fridgeId ? fridgeId : 'no fridgeId'} //
      {tableData ? 'tabledata' : 'no fridgeId'} //
      {QRCodesPresent ? QRCodesPresent : 'no qr codes'} //
      {QRCodesPresent ? getAssetIdFromUrl(QRCodesPresent[0]) : 'no asset id from url'}
    </div> */}



    {!fridgeId && <RedAlert fullwidth={true}>No fridge Id</RedAlert>}
    {showInvalidAssetMessage && <RedAlert fullwidth={true}>Fridge ID #{fridgeId} is invalid</RedAlert>}
    {showNoDigitalDisplayMessage && <div className={`w-full mb-4`}><YellowAlert fullwidth={true}>Fridge ID #{fridgeId} has no digital display. Please enter a reading manually.</YellowAlert></div>}

    {showUnexpectedQrCodeModal && <Modal>
      <div className={`flex flex-col gap-4`}>
        <div className={`flex flex-col gap-2`}>
          <h4 className={`font-righteous text-xl text-brandblue`}>Unexpected QR code</h4>
          {fridgeId && <p>Expecting QR code for fridge Id: #{fridgeId}</p>}
        </div>
        <Button
          text={'Retry'}
          onClick={() => setShowUnexpectedQrCodeModal(false)}
          size={'big'}
          fullwidth={true}
          variant={'primary'}
        />
      </div>
    </Modal>}

    {showReadingDetectedModal &&
      <FridgeReadingDetected
        jpegString={jpegString}
        setShowReadingDetectedModal={setShowReadingDetectedModal}
        // detectedTemperatureReading={null}
        questionAnswer={questionAnswer}
        setQuestionAnswer={setQuestionAnswer}
        answerNote={answerNote}
        setAnswerNote={setAnswerNote}
        answerPhoto={answerPhoto}
        setAnswerPhoto={setAnswerPhoto}
        digitalDisplay={!showNoDigitalDisplayMessage}
        retakePhoto={retakePhoto}
        aiResults={aiResults}
        fridgeId={fridgeId}
        isAnswerManuallyEdited={isAnswerManuallyEdited}
        setIsAnswerManuallyEdited={setIsAnswerManuallyEdited}
      />}



    {/* {!showReadingDetectedModal &&
      <div className={`absolute max-w-32 animate-pulse rounded bg-white px-3 py-1 text-xs`} style={{top: '50%'}}>
        <p>Scan the QR code for fridge ID:</p>
        <p className={`font-bold text-sm`}>#{fridgeId}</p>
      </div>
    } */}



    {/* {!showInvalidAssetMessage && !showReadingDetectedModal && <div
      className={`fixed mt-36 flex flex-col gap-2 pt-24`}>
      <div className={`bg-white py-1 px-2 rounded`}>
        <p className={`text-xs`}>Capture reading for fridge ID:</p>
        <p className={`font-righteous text-brandblue text-xl`}>#{fridgeId}</p>

        {
          questionId &&
          tableData.ChecklistQuestions[questionId].QuestionInstructions &&
          <div className={`py-1`}>
            <YellowAlert size='small'>{tableData.ChecklistQuestions[questionId].QuestionInstructions}</YellowAlert>
          </div>
        }
      </div>


    </div>} */}





  </div>
}
export default QuestionFridgeTemperature