import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../GlobalContext'
import Button from '../formfields/Button'
import { useVideo } from '../context/video/VideoContext';
import Card from '../ui-elements/Card'
import Modal from '../ui-elements/Modal'
import AddButton from '../formfields/AddButton'
import { useNavigate, useParams } from 'react-router-dom';
import RedAlert from '../ui-elements/RedAlert';
import FooterNavButtons from '../shared-components/FooterNavButtons';
import HeaderDetails from '../shared-components/HeaderDetails';
import Spinner from '../ui-elements/Spinner';
import PhotoPreview from './PhotoPreview';
import { subscribe } from '../utils/pubsub';
import { b64toBlob } from '../utils/b64toBlob';
import { error } from 'console';



type Props = {
}

function AddPhotoNote({
}: Props) {


    const {
        tableData,
        setIsVideoMode,
        isVideoPlaying,
        setIsVideoPlaying,
        sendMessageToWebsocket,
        loggedIn,
        userData
    } = useContext(GlobalContext)

    const navigate = useNavigate()
    const { answerId } = useParams()
    const [savingProgress, setSavingProgress] = useState(false)
    const [titleText, setTitleText] = useState('Take a photo note')
    const [subtitleText, setSubtitleText] = useState('')
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [thisAnswer, setThisAnswer] = useState<null | ObjectStringKeyAnyValue>(null)
    const [jpegString, setJpegString] = useState<string | null>(null)
    const { captureAsJPEGString, setQRCodesSearchOptions } = useVideo()
    const [nextButtonText, setNextButtonText] = useState('')
    const [nextButtonAction, setNextButtonAction] = useState<null | AnyFunction>(null)
    const [backButtonAction, setBackButtonAction] = useState<null | AnyFunction>(null)
    const [backButtonText, setBackButtonText] = useState('back')
    const [backButtonUrl, setBackButtonUrl] = useState(`/checklists/`)

    useEffect(() => {
        if (tableData) {
            if (answerId && tableData.ChecklistAnswers && tableData.ChecklistAnswers[answerId]) {
                // get answer details
                const answerObj = tableData.ChecklistAnswers[answerId]
                setThisAnswer(answerObj)
                if (answerObj.Pending === true) {
                    setErrorMessage('Question has not been answered yet')
                } else if (answerObj.PhotoNoteFileName && typeof answerObj.PhotoNoteFileName === 'string') {
                    setErrorMessage('This answer already has a photo note')
                } else {
                    switchVideoOn()
                }
                // set header text with the question title
                const thisQuestionId = answerObj.QuestionId
                if (tableData.ChecklistQuestions && tableData.ChecklistQuestions[thisQuestionId] && tableData.ChecklistQuestions[thisQuestionId].QuestionText) {
                    setTitleText(tableData.ChecklistQuestions[thisQuestionId].QuestionText)
                    setSubtitleText('Take a photo note')
                }
            } else { setErrorMessage('Invalid answer ID') }
        }
    }, [tableData, answerId])

    useEffect(() => {
        if (thisAnswer && jpegString && !errorMessage) {
            // photo captured
            setBackButtonAction(() => () => retakePhoto())
            setBackButtonText('Retake')
            setBackButtonUrl('')
            setNextButtonAction(() => () => saveData())
            setNextButtonText('Save photo note')
        } else if (thisAnswer && !jpegString && !errorMessage) {
            // photo capture mode
            setBackButtonAction(null)
            setBackButtonText('Back')
            setBackButtonUrl(`/checklists/${thisAnswer.ChecklistPeriodId}/${thisAnswer.QuestionId}`)
            setNextButtonAction(() => () => capturePhoto())
            setNextButtonText('📸 Take photo')
        } else if (thisAnswer && errorMessage) {
            setBackButtonAction(null)
            setBackButtonText('Back')
            setBackButtonUrl(`/checklists/${thisAnswer.ChecklistPeriodId}/${thisAnswer.QuestionId}`)
            setNextButtonAction(null)
            setNextButtonText('')
        } else {
            setBackButtonAction(null)
            setBackButtonText('Back')
            setBackButtonUrl('/checklists')
            setNextButtonAction(null)
            setNextButtonText('')
        }
    }, [thisAnswer, jpegString])

    const switchVideoOn = () => {
        setIsVideoMode(true)
        setIsVideoPlaying(true)
        setQRCodesSearchOptions({ 'enabled': true })
    }

    const retakePhoto = () => {
        setJpegString(null)
        setIsVideoPlaying(true)
        setIsVideoMode(true)
    }

    const capturePhoto = () => {
        const jpgData = captureAsJPEGString(600)
        setJpegString && setJpegString(jpgData || null)
        setIsVideoPlaying(false)
        setIsVideoMode(false)
    }



    const saveData = async () => {
        if (thisAnswer && answerId && jpegString) {
            setSavingProgress(true)
            const timestamp = `${Date.now()}`
            let fileName = `note-${answerId}-${timestamp}.jpg`
            await handleImageUpload(answerId, jpegString, fileName, timestamp)
        } else {
            setErrorMessage('Sorry - could not save this answer')
        }
    }


    const handleImageUpload = async (answerId: string, dataString: string, fileName: string, timestamp: string) => {
        const payload = JSON.stringify({
            action: "checklistsClientApp",
            subAction: "requestPresignedUrl",
            fileName: fileName,
            waitingForUploadUrlId: timestamp
        })
        sendMessageToWebsocket(payload)
        const response = await new Promise<void>((resolve, reject) => {
            const unsubscribe = subscribe("returnPresignedUrl", async data => {
                if (data.waitingForUploadUrlId === timestamp) {
                    unsubscribe()
                    const prefix = 'data:image/jpeg;base64,'
                    const fileBody = dataString!.split(prefix)[1]
                    const file = new File([b64toBlob(fileBody)], timestamp, { type: "image/jpeg" })
                    await fetch(data.signedUrl, {
                        method: 'PUT',
                        body: file
                    })
                        .then(response => {
                            // send answer to websocket
                            const payload: ObjectStringKeyAnyValue = {
                                action: "checklistsClientApp",
                                subAction: "saveAnswerNote",
                                answerId: `${answerId}`,
                                photoNoteFileName: data.imageFileName
                            }
                            // if a user is logged in as a guest, add their name to the data
                            if (loggedIn === 'guest') {
                              payload['GuestUserName'] = userData.name
                            }
                            sendMessageToWebsocket(JSON.stringify(payload))

                            // navigate back once the answer is saved (unless its a photo upload)
                            const unsubscribe = subscribe("tableUpdateItemModified", data => {
                                console.log(data)
                                if (data.newRecord && data.newRecord.Id === answerId) {
                                    setSavingProgress(false)
                                    setJpegString(null)
                                    navigate(thisAnswer ? `/checklists/${thisAnswer.ChecklistPeriodId}/${thisAnswer.QuestionId}` : `/checklists/`)
                                    unsubscribe()
                                }
                            })

                            return (response)
                        }).catch((error) => {
                            console.error('Error:', error)
                        })
                }
            })
        })
    }



    return <div
        className={`w-full flex-1 h-full flex flex-col justify-between gap-2`}
    >

        {savingProgress && <Modal
            showCloseButton={true}
            setShowModal={setSavingProgress}
        ><Spinner><p>Saving...</p></Spinner>
        </Modal>}


        <div className={`w-full flex flex-col items-center`}>
            <HeaderDetails
                titleText={titleText}
                instructionsText={subtitleText}
            />
            <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>
                {!tableData && <Spinner><p>Loading...</p></Spinner>}
                {tableData && errorMessage && <RedAlert>{errorMessage}</RedAlert>}


                {jpegString && <PhotoPreview
                    jpegString={jpegString}
                />}


            </div>
        </div>




        <FooterNavButtons
            backButtonUrl={backButtonUrl}
            backButtonText={backButtonText}
            backButtonAction={backButtonAction}
            nextButtonAction={nextButtonAction}
            nextButtonText={nextButtonText}
        />

    </div>
}
export default AddPhotoNote