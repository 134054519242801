import React, { useEffect, useState, useContext } from 'react'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import ProgressBar from '../ui-elements/ProgressBar'
import RedAlert from '../ui-elements/RedAlert'
import { padOutZeros } from '../utils/padOutZeros'
import SummaryCheckWindow from './SummaryCheckWindow'
import { getChecklistProgress } from '../utils/getChecklistProgress'
import YellowAlert from '../ui-elements/YellowAlert'
import { useNavigate } from 'react-router-dom'
import Modal from '../ui-elements/Modal'
import { findNextQuestion } from '../utils/findNextQuestion'
import ChecklistStatus from './ChecklistStatus'
import { sortAllQuestionAnswersForCheckPeriod } from '../utils/sortAllQuestionAnswersForCheckPeriod'

type Props = {
  checklistId: string
  thisChecklist: ObjectStringKeyAnyValue
  // isChecklistActive: boolean
  checkPeriodId?: string
}

function ChecklistSummary({
  checklistId,
  thisChecklist,
  // isChecklistActive,
  checkPeriodId
}: Props) {

  const {
    tableData,
    isSignoffRequired
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const [checklistProgress, setChecklistProgress] = useState<null | ObjectStringKeyAnyValue>(null)
  const [check_period_start, set_check_period_start] = useState<ObjectStringKeyAnyValue | null>(null)
  const [check_period_end, set_check_period_end] = useState<ObjectStringKeyAnyValue | null>(null)
  const [active_check_period, set_active_check_period] = useState<ObjectStringKeyAnyValue | null>(null)
  const [numberOfAlerts, setNumberOfAlerts] = useState(0)
  const [thisCheckPeriodSignoff, setThisCheckPeriodSignoff] = useState<ObjectStringKeyAnyValue | null>(null)
  const [showCheckPeriods, setShowCheckPeriods] = useState(false)
  const [startQuestionId, setStartQuestionId] = useState<string | null>(null)


  useEffect(() => {

    if (checkPeriodId && tableData) {
      const allQuestionAnswersArray = sortAllQuestionAnswersForCheckPeriod(checkPeriodId, tableData)
      const nextQuestion = findNextQuestion(null, allQuestionAnswersArray)
      setStartQuestionId(nextQuestion)

      const progress = getChecklistProgress(checkPeriodId, tableData)
      if (progress) {
        setChecklistProgress(progress)
      }

      const check_period = tableData.ChecklistPeriods[checkPeriodId]
      if (check_period) {
        setThisCheckPeriodSignoff(check_period['Signoff'])
        set_active_check_period(check_period)
        set_check_period_end(check_period['EndHoursMins'])
        set_check_period_start(check_period['StartHoursMinsDay'])

        if (check_period['AnswerAlerts']) {
          let newNumberOfAlerts = 0
          for (const actionToTake in check_period['AnswerAlerts']) {
            if (actionToTake !== 'followUpQuestion') {
              newNumberOfAlerts = newNumberOfAlerts + check_period['AnswerAlerts'][actionToTake].length
            }
          }
          setNumberOfAlerts(newNumberOfAlerts)
        }
      }
    }
  }, [tableData, checkPeriodId, checklistId])


  return <div className={`w-full`}>
    <Card fullwidth={true}>
      <div className={`w-full flex flex-col gap-4 justify-between`}>
        <div className={`w-full text-xs flex flex-col gap-2`}>

          {/* <p>Check period:{checkPeriodId}</p> 
        <p>Checklist: {checklistId}</p>  */}


          <h4 className={`text-lg font-righteous text-brandblue`}>
            {thisChecklist && thisChecklist['ChecklistName'] ? thisChecklist['ChecklistName'] : 'unnamed checklist'}
          </h4>


          {!checklistProgress && false && <RedAlert icon={true} alignment={'left'}>No questions have been set up for this checklist</RedAlert>}

          {thisChecklist && thisChecklist['Schedule'] &&
            thisChecklist['Schedule']['Frequency'] &&
            thisChecklist['Schedule']['Frequency'] !== 'ADHOC' &&
            (!thisChecklist['Schedule']['CheckWindows'] || Object.keys(thisChecklist['Schedule']['CheckWindows']).length === 0) &&
            <RedAlert icon={true} alignment={'left'}>No checking times have been set up for this checklist</RedAlert>}




          {checkPeriodId && checklistProgress && (checklistProgress.numberOfExpectedAnswers !== checklistProgress.numberOfCompletedAnswers) &&
            <ProgressBar
              total={checklistProgress.numberOfExpectedAnswers}
              subtotal={checklistProgress.numberOfCompletedAnswers}
            />}

          <div className={`flex flex-row gap-1 flex-wrap`}>

            {checklistProgress && checkPeriodId &&
              <p>{checklistProgress.numberOfCompletedAnswers} of {checklistProgress.numberOfExpectedAnswers} questions completed. </p>}

            {checkPeriodId && checkPeriodId && check_period_start && check_period_end &&
              <p>Started at:  {padOutZeros(check_period_start['hours'])}:{padOutZeros(check_period_start['minutes'])}, Ends at: {padOutZeros(check_period_end['hours'])}:{padOutZeros(check_period_end['minutes'])}. </p>
            }


            {thisChecklist &&
              thisChecklist['Schedule'] &&
              thisChecklist['Schedule']['CheckWindows'] &&
              Object.keys(thisChecklist['Schedule']['CheckWindows']).length > 0 &&
              <p
                className={`underline hover:opacity-80 cursor-pointer`}
                onClick={() => setShowCheckPeriods(true)}>
                Show {Object.keys(thisChecklist['Schedule']['CheckWindows']).length} check period{Object.keys(thisChecklist['Schedule']['CheckWindows']).length > 1 ? 's' : ''}.</p>
            }
          </div>

          <ChecklistStatus
            checkPeriodId={checkPeriodId}
            checklistProgress={checklistProgress}
            thisCheckPeriodSignoff={thisCheckPeriodSignoff}
          />

          {numberOfAlerts > 0 &&
            <RedAlert icon={true} alignment={`left`}>{numberOfAlerts} alerts raised</RedAlert>}


        </div>

        {/* <p>[{startQuestionId}]</p> */}
        <div className={`w-full flex flex-col sm:flex-row gap-2`}>

          {thisChecklist && checkPeriodId &&
            <Button
              internalLinkUrl={`/checklists/${checkPeriodId}`}
              text={`All questions`}
              variant={`primary`}
              fullwidth={true}
            //size={'big'}
            />}

          {thisChecklist &&
            checkPeriodId &&
            startQuestionId &&
            startQuestionId !== 'AllQuestionsComplete' &&
            checklistProgress && (checklistProgress.numberOfExpectedAnswers !== checklistProgress.numberOfCompletedAnswers) &&
            <Button
              internalLinkUrl={`/checklists/${checkPeriodId}/${startQuestionId}`}
              text={checklistProgress && checklistProgress.numberOfCompletedAnswers > 0 ? `Continue checklist` : `Start checklist`}
              variant={`primary`}
              fullwidth={true}
            //size={'big'}
            />}

          {isSignoffRequired && !thisCheckPeriodSignoff && checklistProgress && (checklistProgress.numberOfExpectedAnswers === checklistProgress.numberOfCompletedAnswers) &&
            <Button
              internalLinkUrl={`/checklists/${checkPeriodId}/complete-checklist`}
              text={`Sign off checklist`}
              variant={`primary`}
              fullwidth={true}
            //size={'big'}
            />}


          {
            thisChecklist &&
            thisChecklist.Schedule &&
            thisChecklist.Schedule.Frequency &&
            thisChecklist.Schedule.Frequency === 'ADHOC' &&
            !checkPeriodId &&
            <Button
              internalLinkUrl={`/start-checklist/${thisChecklist['Id']}`}
              text={`Start checklist`}
              variant={`primary`}
              fullwidth={true}
            //size={'big'}
            />}


        </div>

      </div>
    </Card>


    {showCheckPeriods && <Modal showCloseButton={true} setShowModal={setShowCheckPeriods}>
      <h4 className={`text-lg font-righteous text-brandblue`}>Check periods</h4>
      <div className={`mt-2 mb-4 flex flex-col gap-2`}>{
        thisChecklist &&
          thisChecklist['Schedule'] &&
          thisChecklist['Schedule']['CheckWindows'] &&
          Object.keys(thisChecklist['Schedule']['CheckWindows']).length > 0 ?
          Object.keys(thisChecklist['Schedule']['CheckWindows']).map((checkWindowId, index) => {
            return <SummaryCheckWindow
              key={index}
              checkWindowId={checkWindowId}
              checkWindow={thisChecklist['Schedule']['CheckWindows'][checkWindowId]}
            />
          }) :

          thisChecklist['Schedule'] &&
          thisChecklist['Schedule']['Frequency'] &&
          thisChecklist['Schedule']['Frequency'] !== 'ADHOC' &&
          <RedAlert icon={true} alignment={'left'}>No checking times have been set up for this checklist
            {JSON.stringify(thisChecklist)}</RedAlert>
      }
      </div>
      <Button
        onClick={() => setShowCheckPeriods(false)}
        text={`Close`}
        variant={`primary`}
        fullwidth={true}
      //size={'big'}
      />
    </Modal>}
  </div>
}

export default ChecklistSummary