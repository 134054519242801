

import React, { useContext, useState, useRef, useEffect } from 'react'
import { GlobalContext } from '../GlobalContext'
import CloudformationOutputs from '../CloudformationOutputs.json'
import RedAlert from '../ui-elements/RedAlert'

type Props = {
    setSignatureCanvasData: React.Dispatch<React.SetStateAction<any>>
}

function SignatureCanvas({
    setSignatureCanvasData
}: Props) {


    const canvasRef = useRef(null)
    const [isDrawing, setIsDrawing] = useState(false)
    const [isViewportTallEnough, setIsViewportTallEnough] = useState(true)
    const [lastPos, setLastPos] = useState({ x: 0, y: 0 })


    useEffect(() => {
        handleScrolling()
        const canvas: any = canvasRef.current
        if (canvas) {
            const ctx = canvas.getContext('2d')
            canvas.width = (window.innerWidth * 0.7) < 500 ? (window.innerWidth * 0.7) : 500
            canvas.height = canvas.width * 0.5
            canvas.background = '#ffffff'
            ctx.lineWidth = 2
            ctx.lineCap = 'round'
            ctx.strokeStyle = 'black'
            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, canvas.width, canvas.height)

            const handleResize = () => {
                handleScrolling()
                setSignatureCanvasData('')
                canvas.width = (window.innerWidth * 0.7) < 500 ? (window.innerWidth * 0.7) : 500
                canvas.height = canvas.width * 0.5
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, canvas.width, canvas.height)
            }

            window.addEventListener('resize', handleResize);
            // window.addEventListener('orientationchange', stopScrolling);
            return () => {
                clearBodyStyles()
                window.removeEventListener('resize', handleResize);
                // window.removeEventListener('orientationchange', stopScrolling);
            }
        } else {
            console.log('no canvas')
        }
    }, [])



    const handleScrolling = () => {
        // Mobile: the browser scrolling makes it difficult to interact with the canvas.
        // Lock the window orientation and disable scrolling on these devices.
        if (window.innerWidth < window.innerHeight) {
            setIsViewportTallEnough(true)
        } else {
            setIsViewportTallEnough(false)
        }
        if (
            window.innerWidth < window.innerHeight &&
            navigator.userAgent.includes('iPhone')
        ) {
            document.body.className = 'portraitMode'
        } else {
            document.body.className = ''
        }
    }

    const clearBodyStyles = () => {
        document.body.className = ''
    }


    const getPos = (e: any) => {
        // @ts-ignore
        const rect = canvasRef.current.getBoundingClientRect()
        if (e.touches && e.touches.length > 0) {
            return {
                x: e.touches[0].clientX - rect.left,
                y: e.touches[0].clientY - rect.top,
            }
        } else {
            return {
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            }
        }
    }

    const startDrawing = (e: any) => {
        //document.body.style.overflow = 'hidden';

        const pos = getPos(e)
        setLastPos(pos)
        setIsDrawing(true)
    }

    const draw = (e: any) => {
        if (!isDrawing) return
        // @ts-ignore
        const ctx = canvasRef.current.getContext('2d')
        const pos = getPos(e)

        ctx.beginPath()
        ctx.moveTo(lastPos.x, lastPos.y)
        ctx.lineTo(pos.x, pos.y)
        ctx.stroke()

        setLastPos(pos)
    }

    const stopDrawing = () => {
        // only stop drawing if some drawing has already started
        if (isDrawing){
            setIsDrawing(false)
            const canvas: any = canvasRef.current
            const dataUrl = canvas && canvas.toDataURL('image/jpeg') || ''
            setSignatureCanvasData(dataUrl)
        }
    }



    return (
        <div>
            {!isViewportTallEnough && <RedAlert>Please ensure device is in portrait mode</RedAlert>}

            {isViewportTallEnough && <p className={`text-center mb-2`}>Draw your signature below:</p>}
            <canvas
            className={`
                ${isViewportTallEnough ? '' : ' hidden '} 
                `}
                ref={canvasRef}
                onMouseDown={startDrawing}
                onMouseMove={draw}
                onMouseUp={stopDrawing}
                onMouseLeave={stopDrawing}
                onTouchStart={startDrawing}
                onTouchMove={draw}
                onTouchEnd={stopDrawing}
            />
        </div>
    )
}


export default SignatureCanvas


